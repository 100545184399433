import React from "react"
// import { graphql } from "gatsby"
import Layout from "../components/layout"
// import ReadMore from "../components/readMore"
import {
  PageHeader,
  Section,
  Content,
  Row,
  Col,
} from "../styles/StyledElements"
// import { Tabs, Tab } from "../components/tabs"
// import { contentMapAirtable } from "../components/pageUtilities"

export default function StyleGuide({ data }) {
  return (
    <Layout>
      <PageHeader>
        <Content>
          <Row>
            <Col>
              <h1>Content</h1>
            </Col>
          </Row>
        </Content>
      </PageHeader>
      <Section>
        <Content>
          <Row>
            <Col>
              <p>[STYLES]</p>
              <h1>H1: Heading</h1>
              <h2>H2: Heading</h2>
              <h3>H3: Heading</h3>
              <h4>H4: Heading</h4>
              <h5>H5: Heading</h5>
              <h6>H6: Heading</h6>
              <p>
                Standard Paragraph Lorem ipsum <strong>dolor sit</strong> amet,
                consectetur <em>adipiscing elit</em>, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.
                <sup>1 Superscript</sup> Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo
                <sub>2 Subscript</sub> consequat.
              </p>
            </Col>
          </Row>
        </Content>
      </Section>
    </Layout>
  )
}
